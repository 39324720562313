<template>
  <div class="wrap">
    <van-nav-bar :title="title || $t('newTabbar.goodsPage.cooperate')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <div class="content" v-if="content">
      <div class="content_item">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { NavBar,Toast,icon } from "vant";
import { INFOR_DETAIL } from "@/api"
export default{
  components: {
    [NavBar.name]:NavBar,
    [icon.name]: icon
  },
  data(){
    return{
      content:'',
      title:'',
    }
  },
  created(){
    console.log(this.$route.query.id);
    this.getDeatil(this.$route.query.id);
  },
  methods:{
    async getDeatil(id){
      Toast.loading()
      try {
        const res = await INFOR_DETAIL(id)
        console.log(res);
        this.title = res.data.title
        this.content = res.data.content
        setTimeout(() => {
          Toast.clear()
        }, 500);
      } catch (err) {
        Toast.clear()
        console.error(err);
      }
    }
  }
}
</script>
<style lang="less" scoped>
@white: #fff;
@themeColor: var(--navBg);

.widthCalc(@name, @width) {
  @{name}: calc(~"@{width}" / 16)
}

.wrap {
  // min-height: calc(100vh - (100rem / 16));
  min-height: 100vh;
  background-color: #f4f8fb;
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: @themeColor;
      .widthCalc(height, 50rem);
    }
    
    /deep/ .van-nav-bar__title {
      color: @white;
    }
  }
  .content{
    .widthCalc(padding, 16rem);
    .content_item{
      background-color: @white;
      .widthCalc(border-radius, 8rem);
      .widthCalc(padding, 16rem);
      .widthCalc(font-size, 14rem);
      box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
    }
  }
}
</style>